import React, {useMemo} from 'react'
import { useTable, useSortBy, useBlockLayout, useRowState } from 'react-table'
import { useSticky } from 'react-table-sticky'
import theData from './banqueProCartes.json'

import { COLUMNS as theColumns } from './banqueProCartes-columnsMobile'
import "bootstrap/dist/css/bootstrap-grid.css"
import * as Bs from "react-bootstrap"
import './table.css'
import { Styles } from './TableStyles'




function BanquePro(props) {

let rowArray = []

if(props.row){
  rowArray = props.row
}


console.log('rowprops', props.row)


  function checkFilter(line){
    return line
  }

  function checkColFilter(col){
    if(props.id === 'postTable'){
      return col.post === 'yes'
    } else if(props.id === 'mobileTable'){
      return col.mobile === 'yes'
    }
    else return col.wide === 'yes'
  }


  console.log('the Data', theData.filter(checkFilter))

  const newData = theData.filter(checkFilter)
  const newColumns = theColumns.filter(checkColFilter)
  
  const columns = useMemo(()=> newColumns,[])
  const dataQonto = useMemo(() => newData, [])


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns: columns,
    data: dataQonto,
  //   initialState: {
  //     sortBy: [
  //         {
  //             id: 'Tarifs',
  //             desc: false
  //         }
  //     ]
  // }
  }, 
  useSortBy,
  useBlockLayout,
  useSticky)

  console.group('columns', columns)

  return (
    <>


    <Bs.Container style={{marginTop: 20, maxWidth: 1280, marginLeft: -10, marginRight: -10}}>

<Styles id={props.id}>
      <div {...getTableProps()} className="table sticky" >
        {console.log('table', getTableProps)}
        <div className="header">
          {headerGroups.map((headerGroup) => (
            
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              
              {headerGroup.headers.map((column) => (
               
                <div 
                {...column.getHeaderProps(column.getSortByToggleProps())}
                
                {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                  <span style={{cursor: 'pointer'}}>
                  {/* {column.isSorted ? (column.isSortedDesc ?  <FontAwesomeIcon icon={faSortDown} style={{marginLeft: 10}}/>: <FontAwesomeIcon icon={faSortUp} style={{marginLeft: 10}}/>): <FontAwesomeIcon icon={faSort} style={{marginLeft: 10}}/> } */}
                </span>
                </div>
               
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body" style={{fontSize: 18, color: 'black'}}>
        
          {rows.map((row) => {
            // if(row.cells[0].value.split(',')[0] === 'Qonto'){

              // console.log('row value', row.getRowProps())
            // }
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr" 
              >
                {row.cells.map((cell) => (
                  <div {...cell.getCellProps()} className="td">
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        
      </div>
    </Styles>
    </Bs.Container>
    </>
  )
}

export default BanquePro
