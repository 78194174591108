import React from 'react'



export const COLUMNS = [


  // {
  //   Header: 'Services',
  //   accessor: "Services",
  //   width: 230,
  //   padding: 27,
  //   wide: 'yes',
  //   post: 'no',
  //   mobile: 'no',
  //   Cell : e => {
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //     } else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}} id='colWide'>
  //         <div style={{fontSize: 16, marginTop: 10, whiteSpace: 'normal', fontWeight: 300}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div> */}
  //       </div>
  //       )
  //     }
  // }},

  {
    Header: () => (
      <div style={{float: 'left', marginLeft:0, marginRight: -9, marginTop: -10}}>
        <a href={`/go/?go=Managerone&url=https://signup.manager.one/?offer=Bankbank`}
           target='_blank' rel="noreferrer">
           <img style={{width: 150}} src={`/logos/managerone.png`} alt='Manager.one'></img>
         </a>
        </div>),
    accessor: "ManagerOne",
    width: 170,
    padding: 27,
    wide: 'yes',
    post: 'no',
    mobile: 'yes',
    Cell : e => {
      if(e.value === 'illimité'){
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
            <div style={{fontSize: 20, padding: 8, textAlign: 'center'}}>{e.value}</div>
          </div> 
        )
      } else {
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}} id='colWide'>
          <div style={{fontSize: 24, marginTop: 10, textAlign: 'center'}}>{e.value}</div>
          {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div> */}
        </div>
        )
      }
  }},

  // {
  //   Header: () => (<div style={{float: 'left', marginLeft:0, marginRight: -9, marginTop: -10}}><div>Banques</div><div style={{marginTop: -5}}>Traditionnelles</div></div>),
  //   accessor: "BanquesTraditionnelles",
  //   width: 150,
  //   padding: 27,
  //   wide: 'yes',
  //   post: 'no',
  //   mobile: 'no',
  //   Cell : e => {
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //     } else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}} id='colWide'>
  //         <div style={{fontSize: 24, marginTop: 10}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div> */}
  //       </div>
  //       )
  //     }
  // }},

//  {
//     Header: () => (
//     <div style={{float: 'left', marginLeft:0, marginRight: -9, marginTop: -10}}>
//       <a href={`/go/?go=AmericanExpress&url=https://www.americanexpress.com/fr/`}
//          target='_blank' rel="noreferrer">
//          <img style={{width: 150}} src={`/logos/american-express.png`} alt='American Express'></img>
//        </a>
//       </div>),
//     accessor: "AmericanExpress",
//     width: 170,
//     padding: 27,
//     wide: 'yes',
//     post: 'no',
//     mobile: 'no',
//     Cell : e => {
//       if(e.value === 'illimité'){
//          return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//             <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
//           </div> 
//         )
//       } else {
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}} id='colWide'>
//           <div style={{fontSize: 24, marginTop: 10}}>{e.value}</div>
//           {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div> */}
//         </div>
//         )
//       }
//   }},

  // {
  //   Header: () => (
  //     <div style={{float: 'left', marginLeft:0, marginRight: -9, marginTop: -10}}>
  //       <a href={`/go/?go=Qonto&url=https://lb.affilae.com/r/?p=5ce4f2a2b6302009e29d84f3&af=136&lp=https%3A%2F%2Fqonto.com%2Ffr%2F%3Futm_source%3Daffiliate%26utm_medium%3Dcpl%26utm_campaign%3Dbankbank`}
  //          target='_blank' rel="noreferrer">
  //          <img style={{width: 150}} src={`/logos/qonto.png`} alt='Qonto'></img>
  //        </a>
  //       </div>),
  //   accessor: "Qonto",
  //   width: 170,
  //   padding: 27,
  //   wide: 'yes',
  //   post: 'no',
  //   mobile: 'no',
  //   Cell : e => {
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //     } else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}} id='colWide'>
  //         <div style={{fontSize: 24, marginTop: 10}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div> */}
  //       </div>
  //       )
  //     }
  // }},

  // {
  //   Header: () => (

  //   //   <div style={{marginTop: 15}}>
  //   //   <a href={`/go/?go=${val[0]}&url=${val[2]}`}
  //   //   state={{url: val[1]}}
  //   //   target='_blank' rel="noreferrer">
  //   //   <img style={{width: 150}} src={`/logos/${val[0].replace(' ', '-').replace('.', '').replace('é', 'e').replace('é', 'e').replace('é', 'e').replace('é', 'e').toLowerCase()}.png`} alt={`${e.value}`}></img>
  //   // </a>



  //     <div style={{float: 'left', marginLeft:0, marginRight: -9, marginTop: -10}}>
  //       <a href={`/go/?go=Mooncard&url=https://www.mooncard.co/`}
  //         state={{url: 'Mooncard'}}
  //          target='_blank' rel="noreferrer">
  //          <img style={{width: 150}} src={`/logos/mooncard.png`} alt='Mooncard'></img>
  //        </a>
  //       </div>),
  //   accessor: "Mooncard",
  //   width: 160,
  //   padding: 27,
  //   wide: 'yes',
  //   post: 'no',
  //   mobile: 'no',
  //   Cell : e => {
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //     } else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}} id='colWide'>
  //         <div style={{fontSize: 24, marginTop: 10}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div> */}
  //       </div>
  //       )
  //     }
  // }},

  {
    Header: () => (
      <div style={{float: 'left', marginLeft:0, marginRight: -9, marginTop: -10}}>
        <a href={`/go/?go=Spendesk&url=https://www.spendesk.com/fr/`}
           target='_blank' rel="noreferrer">
           <img style={{width: 150}} src={`/logos/spendesk.png`} alt='Spendesk'></img>
         </a>
        </div>),
    accessor: "Spendesk",
    width: 170,
    padding: 27,
    wide: 'yes',
    post: 'no',
    mobile: 'yes',
    Cell : e => {
      if(e.value === 'illimité'){
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
            <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
          </div> 
        )
      } else {
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}} id='colWide'>
          <div style={{fontSize: 24, marginTop: 10}}>{e.value}</div>
          {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div> */}
        </div>
        )
      }
  }},

]